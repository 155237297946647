import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import LoadingBar from './loadingbar';
import './App.css';

function App() {
  const [progress, setProgress] = useState(0);
  const [inProp, setInProp] = useState(false); // State to control the transition

  useEffect(() => {
    setInProp(true); // Start the enter transition when the component mounts

    const interval = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress < 100) {
          return oldProgress + 10;
        }
        clearInterval(interval);
        return 100;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      // Trigger the exit transition
      setInProp(false);
      setTimeout(() => {
        // Redirect to the home page after the exit transition
        window.location.href = '/home'; // Correct the path to your homepage
      }, 500); // Match the duration of your fade-out transition
    }
  }, [progress]);

  return (
    <CSSTransition in={inProp} timeout={500} classNames="fade" unmountOnExit>
      <div className="App">
        <div className="container">
          <h1>DYLAN MALTOS</h1>
          <LoadingBar progress={progress} />
        </div>
      </div>
    </CSSTransition>
  );
}

export default App;
