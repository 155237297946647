import React from 'react';
import './loadingbar.css'; // Assume you'll create a CSS file for styling

const LoadingBar = ({ progress }) => {
  const barStyle = {
    width: `${progress}%`, // Control the width of the loading bar with progress
  };

  return (
    <div className="loading-bar-container">
      <div className="loading-bar" style={barStyle}></div>
    </div>
  );
};

export default LoadingBar;